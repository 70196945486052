"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Menu = function Menu() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Your code here
    var $mobileMenu = document.querySelector('.mobile-menu');
    var $menuTriggers = document.querySelectorAll('.menu-toggle');
    console.log('coucou');
    if ($mobileMenu && $menuTriggers.length) {
      $menuTriggers.forEach(function ($trigger) {
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();
          $mobileMenu.classList.toggle('is-active');
        });
      });
    }
    var $numbers = document.querySelectorAll('.home-numbers .number-text span');
    if ($numbers.length) {
      // Numbers animation
      var animateValue = function animateValue(obj, start, end, duration) {
        var startTimestamp = null;
        var step = function step(timestamp) {
          if (!startTimestamp) startTimestamp = timestamp;
          var progress = Math.min((timestamp - startTimestamp) / duration, 1);
          obj.innerHTML = Math.floor(progress * (end - start) + start);
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
      };
      var isInViewport = function isInViewport(element) {
        var rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      };
      document.addEventListener('scroll', function () {
        $numbers.forEach(function ($number) {
          if (isInViewport($number) && !$number.classList.contains('is-animated')) {
            $number.classList.add('is-animated');
            console.log($number.textContent, parseInt($number.textContent.replace(' ', ''), 10));
            animateValue($number, 0, parseInt($number.textContent.replace(' ', ''), 10), 3500);
          }
        });
      }, {
        passive: true
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();